import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ShadowStyleDto } from '@backoffice/data-access/editor';

@Component({
    selector: 'codex-shadow-picker',
    templateUrl: './shadow-picker.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShadowPickerComponent implements OnInit {
    @Input()
    set shadowStyle(shadowStyle: ShadowStyleDto) {
        if (shadowStyle) {
            this._shadowStyle = shadowStyle;
        } else {
            this._shadowStyle = this.defaultShadowStyle();
        }
    }

    _shadowStyle: ShadowStyleDto;

    @Output()
    changeShadow: EventEmitter<{
        shadow: {
            shadowStyle: ShadowStyleDto;
        };
    }> = new EventEmitter();

    constructor() {}

    ngOnInit(): void {}

    defaultShadowStyle(): ShadowStyleDto {
        return {} as ShadowStyleDto;
    }

    onChangeColor($event: any) {
        this._shadowStyle.color = $event.value;
        this.onChangeShadow();
    }

    onChangeOffsetX($event) {
        this._shadowStyle.offsetX = $event.value;
        this.onChangeShadow();
    }

    onChangeOffsetY($event) {
        this._shadowStyle.offsetY = $event.value;
        this.onChangeShadow();
    }

    onChangeBlurRadius($event) {
        this._shadowStyle.blurRadius = $event.value;
        this.onChangeShadow();
    }

    onChangeSpreadRadius($event) {
        this._shadowStyle.spreadRadius = $event.value;
        this.onChangeShadow();
    }

    onChangeShadow() {
        this.changeShadow.emit({
            shadow: {
                shadowStyle: this._shadowStyle,
            },
        });
    }
}
