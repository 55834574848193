import { Color } from '../../designsystem/models/color/color.model';
import { generateColorStyle, generateColorStyleV2 } from './color.style';
import { ColorV2 } from '../../designsystem/models/color/colorv2.model';
import { generatePartStyleReferenceCss } from './part-style-reference.dto';
import { FontStyleDto, getUnitCSS } from './font.style.dto';

export interface BorderStyleDto {
    borderType: string;
    borderColor: string;
    borderSize: string;
    borderRadius: string;
    color?: Color;
    colorV2?: ColorV2;
}

export function generateBorderStyles(
    borderStyle: BorderStyleDto | null | undefined,
    borderLeftStyle: BorderStyleDto | null | undefined,
    borderRightStyle: BorderStyleDto | null | undefined,
    borderTopStyle: BorderStyleDto | null | undefined,
    borderBottomStyle: BorderStyleDto | null | undefined
) {
    let style = '';
    if (borderStyle) {
        style += generateTypeBorderStyle('border', borderStyle);
    }
    if (borderLeftStyle) {
        style += generateTypeBorderStyle('border-left', borderLeftStyle);
    }
    if (borderRightStyle) {
        style += generateTypeBorderStyle('border-right', borderRightStyle);
    }
    if (borderTopStyle) {
        style += generateTypeBorderStyle('border-top', borderTopStyle);
    }
    if (borderBottomStyle) {
        style += generateTypeBorderStyle('border-bottom', borderBottomStyle);
    }
    return style;
}

export function generateInputBorderStyle(
    partId,
    status,
    borderStyleDto: BorderStyleDto,
    hoverBorderStyleDto?: BorderStyleDto,
    focusBorderStyleDto?: BorderStyleDto
) {
    let style = '';
    if (borderStyleDto) {
        style += '--input-border-width:' + borderStyleDto.borderSize + ';';
        style += '--input-border-color:' + borderStyleDto.borderColor + ';';
        style += '--input-border-radius:' + borderStyleDto.borderRadius + ';';
        style += '--input-border-style:' + borderStyleDto.borderType + ';';
    }

    if (hoverBorderStyleDto) {
        style += '--input-hover-border-width:' + hoverBorderStyleDto.borderSize + ';';
        style += '--input-hover-border-color:' + hoverBorderStyleDto.borderColor + ';';
        style += '--input-hover-border-radius:' + hoverBorderStyleDto.borderRadius + ';';
        style += '--input-hover-border-style:' + hoverBorderStyleDto.borderType + ';';
    }

    if (focusBorderStyleDto) {
        style += '--input-focus-border-width:' + focusBorderStyleDto.borderSize + ';';
        style += '--input-focus-border-color:' + focusBorderStyleDto.borderColor + ';';
        style += '--input-focus-border-radius:' + focusBorderStyleDto.borderRadius + ';';
        style += '--input-focus-border-style:' + focusBorderStyleDto.borderType + ';';
    }

    if (false && borderStyleDto) {
        style += '#id-' + partId + ' .mat-form-field-appearance-outline .mdc-notched-outline .mdc-notched-outline__leading {';
        style += 'border-right-style: none !important;';
        style += 'border-top-right-radius: 0px !important;';
        style += 'border-bottom-right-radius: 0px !important;';
        style += '}';

        style += '#id-' + partId + ' .mat-form-field-appearance-outline .mdc-notched-outline .mdc-notched-outline__trailing {';
        style += 'border-left-style: none !important;';
        style += 'border-top-left-radius: 0px !important;';
        style += 'border-bottom-left-radius: 0px !important;';
        style += '}';

        style += '#id-' + partId + '  .mat-form-field-appearance-outline .mdc-notched-outline--notched .mdc-notched-outline__notch {';
        style += 'border-top-style: none !important;';
        style += '}';

        style += '#id-' + partId + ' .mat-form-field-appearance-outline .mdc-notched-outline .mdc-notched-outline__notch {';
        style += 'border-right-style: none !important;';
        style += 'border-left-style: none !important;';
        style += 'border-bottom-left-radius: 0px !important;';
        style += 'border-bottom-right-radius: 0px !important;';
        style += 'border-top-left-radius: 0px !important;';
        style += 'border-top-right-radius: 0px !important;';
        style += '}';

        style += '#id-' + partId + ' .mat-form-field-appearance-outline.mat-focused .mdc-notched-outline .mdc-notched-outline__notch {';
        style += 'border-top-width: 0px !important;';
        style += '}';

        if (
            (borderStyleDto.borderSize || borderStyleDto.borderType || borderStyleDto.borderRadius || borderStyleDto.borderColor) &&
            status === ''
        ) {
            style += '#id-' + partId + ' .mat-form-field-appearance-outline .mdc-notched-outline .mdc-notched-outline__leading,';
            style += '#id-' + partId + ' .mat-form-field-appearance-outline .mdc-notched-outline .mdc-notched-outline__notch,';
            style += '#id-' + partId + ' .mat-form-field-appearance-outline .mdc-notched-outline .mdc-notched-outline__trailing {';
            if (borderStyleDto.borderSize) {
                style += 'border-width:' + borderStyleDto.borderSize + ';';
            }
            if (borderStyleDto.borderType) {
                style += 'border-style:' + borderStyleDto.borderType + ';';
            }
            if (borderStyleDto.borderRadius) {
                style += 'border-radius:' + borderStyleDto.borderRadius + ';';
            }
            style += 'border-color:' + borderStyleDto.borderColor + ';';
            style += '}';
        }

        if (
            (borderStyleDto.borderSize || borderStyleDto.borderType || (borderStyleDto.borderRadius && borderStyleDto.borderColor)) &&
            status === ':hover'
        ) {
            style += '#id-' + partId + ' .mat-form-field-appearance-outline .mdc-notched-outline:hover .mdc-notched-outline__leading,';
            style += '#id-' + partId + ' .mat-form-field-appearance-outline .mdc-notched-outline:hover .mdc-notched-outline__notch,';
            style += '#id-' + partId + ' .mat-form-field-appearance-outline .mdc-notched-outline:hover .mdc-notched-outline__trailing {';
            if (borderStyleDto.borderSize) {
                style += 'border-width:' + borderStyleDto.borderSize + ';';
            }
            if (borderStyleDto.borderType) {
                style += 'border-style:' + borderStyleDto.borderType + ';';
            }
            if (borderStyleDto.borderRadius) {
                style += 'border-radius:' + borderStyleDto.borderRadius + ';';
            }
            style += 'border-color:' + borderStyleDto.borderColor + ';';
            style += '}';
        }

        if (
            (borderStyleDto.borderSize || borderStyleDto.borderType || borderStyleDto.borderRadius || borderStyleDto.borderColor) &&
            status === ':focus'
        ) {
            style +=
                '#id-' + partId + ' .mat-form-field-appearance-outline.mat-focused .mdc-notched-outline .mdc-notched-outline__leading,';
            style += '#id-' + partId + ' .mat-form-field-appearance-outline.mat-focused .mdc-notched-outline .mdc-notched-outline__notch,';
            style +=
                '#id-' + partId + ' .mat-form-field-appearance-outline.mat-focused .mdc-notched-outline .mdc-notched-outline__trailing {';
            if (borderStyleDto.borderSize) {
                style += 'border-width:' + borderStyleDto.borderSize + ';';
            }
            if (borderStyleDto.borderType) {
                style += 'border-style:' + borderStyleDto.borderType + ';';
            }
            if (borderStyleDto.borderRadius) {
                style += 'border-radius:' + borderStyleDto.borderRadius + ';';
            }
            style += 'border-color:' + borderStyleDto.borderColor + ';';
            style += '}';
        }
    }
    return style;
}

export function generateTypeBorderStyleWithVars(
    borderStyleDto: BorderStyleDto,
    borderWidthVar: string,
    borderRadiusVar: string,
    borderTypeVar: string,
    borderColorVar: string
) {
    let style = '';
    if (borderStyleDto) {
        if (borderStyleDto.borderRadius) {
            // DEPRECATED
            style += borderRadiusVar + ':' + borderStyleDto.borderRadius + ';';
        }

        if (borderStyleDto.borderType) {
            // DEPRECATED
            style += borderTypeVar + ':' + borderStyleDto.borderType + ';';
        }

        if (borderStyleDto.borderSize) {
            // DEPRECATED
            style += borderWidthVar + ':' + borderStyleDto.borderSize + ';';
        }

        if (borderStyleDto.colorV2) {
            // DEPRECATED
            style += borderColorVar + ':' + generateColorStyleV2(borderStyleDto.colorV2) + ';';
        }
    }
    return style;
}

export function generateTypeBorderStyle(type: string, borderStyleDto: BorderStyleDto) {
    let style = '';
    if (borderStyleDto.borderSize) {
        style += type + '-width:' + borderStyleDto.borderSize + ';';
    }
    if (borderStyleDto.borderType) {
        style += type + '-style:' + borderStyleDto.borderType + ';';
    }
    if (borderStyleDto.borderRadius) {
        style += type + '-radius:' + borderStyleDto.borderRadius + ';';
    }
    if (borderStyleDto.borderColor) {
        style += type + '-color:' + borderStyleDto.borderColor + ';';
    }
    if (borderStyleDto.color) {
        style += type + '-color:' + generateColorStyle(borderStyleDto.color) + ';';
    }
    return style;
}
