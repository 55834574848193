<ng-container *ngIf="{ partPositioningData: partPositioningData$ | async } as observables">
    <h4 class="!text-sm !font-bold !mb-1 !mt-1">{{ label }}</h4>
    <ng-container *ngIf="showGridInput && observables.partPositioningData?.currentPartPositioning">
        <div class="flex mb-2 gap-2">
            <codex-viewport-selector
                class="w-[calc((100%/2)-5px)] flex gap-1"
                [indicateResponsiveSettings]="responseSettingsForSizeX(observables.partPositioningData)"
                [viewPort]="observables.partPositioningData.currentPartPositioning.sizeX"
                [unit]="observables.partPositioningData.currentPartPositioning.sizeXUnit"
                [allowedUnits]="allowedUnits"
                label="W"
                (viewPortChange)="onChangeSizeX($event, observables.partPositioningData.currentPartPositioning)">
                <ng-template #partPositioningSettings>
                    <ng-container
                        *ngFor="let partPositioning of observables.partPositioningData.partPositions; trackBy: identifyPartPositioning">
                        <div
                            *ngIf="partPositioning.screenType !== observables.partPositioningData.currentPartPositioning.screenType"
                            class="flex gap-2 items-center mb-2">
                            <svg *ngIf="partPositioning.screenType === 'SMALL'" class="icon" aria-hidden="true" focusable="false">
                                <use href="#mobile-screens" class="stroke-white"></use>
                            </svg>
                            <svg *ngIf="partPositioning.screenType === 'LARGE'" class="icon" aria-hidden="true" focusable="false">
                                <use href="#tablet-screens" class="stroke-white"></use>
                            </svg>
                            <svg
                                *ngIf="partPositioning.screenType === 'EXTRA_EXTRA_LARGE'"
                                class="icon"
                                aria-hidden="true"
                                focusable="false">
                                <use href="#large-screens" class="stroke-white"></use>
                            </svg>
                            <svg *ngIf="partPositioning.screenType === 'ALL_SCREENS'" class="icon" aria-hidden="true" focusable="false">
                                <use href="#all-screens" class="fill-white"></use>
                            </svg>
                            <codex-viewport-selector
                                class="flex gap-1"
                                [viewPort]="partPositioning.sizeX"
                                [unit]="partPositioning.sizeXUnit"
                                [allowedUnits]="allowedUnits"
                                label="W"
                                (viewPortChange)="onChangeSizeX($event, partPositioning)">
                            </codex-viewport-selector>
                            <button
                                class="text-button"
                                type="button"
                                (click)="$event.stopPropagation()"
                                [matTooltip]="'v2.template.style.reset' | translate">
                                Reset
                            </button>
                        </div>
                    </ng-container>
                </ng-template>
            </codex-viewport-selector>
            <codex-viewport-selector
                class="w-[calc((100%/2)-5px)] flex gap-1"
                [indicateResponsiveSettings]="responseSettingsForSizeY(observables.partPositioningData)"
                [viewPort]="observables.partPositioningData.currentPartPositioning.sizeY"
                [unit]="observables.partPositioningData.currentPartPositioning.sizeYUnit"
                [allowedUnits]="allowedUnits"
                label="H"
                (viewPortChange)="onChangeSizeY($event, observables.partPositioningData.currentPartPositioning)">
                <ng-template #partPositioningSettings>
                    <ng-container
                        *ngFor="let partPositioning of observables.partPositioningData.partPositions; trackBy: identifyPartPositioning">
                        <div
                            *ngIf="partPositioning.screenType !== observables.partPositioningData.currentPartPositioning.screenType"
                            class="flex gap-2 items-center mb-2">
                            <svg *ngIf="partPositioning.screenType === 'SMALL'" class="icon" aria-hidden="true" focusable="false">
                                <use href="#mobile-screens" class="stroke-white"></use>
                            </svg>
                            <svg *ngIf="partPositioning.screenType === 'LARGE'" class="icon" aria-hidden="true" focusable="false">
                                <use href="#tablet-screens" class="stroke-white"></use>
                            </svg>
                            <svg
                                *ngIf="partPositioning.screenType === 'EXTRA_EXTRA_LARGE'"
                                class="icon"
                                aria-hidden="true"
                                focusable="false">
                                <use href="#large-screens" class="stroke-white"></use>
                            </svg>
                            <svg *ngIf="partPositioning.screenType === 'ALL_SCREENS'" class="icon" aria-hidden="true" focusable="false">
                                <use href="#all-screens" class="fill-white"></use>
                            </svg>
                            <codex-viewport-selector
                                class="flex gap-1"
                                [viewPort]="partPositioning.sizeY"
                                [unit]="partPositioning.sizeYUnit"
                                [allowedUnits]="allowedUnits"
                                label="H"
                                (viewPortChange)="onChangeSizeY($event, partPositioning)">
                            </codex-viewport-selector>
                            <button
                                class="text-button"
                                type="button"
                                (click)="$event.stopPropagation()"
                                [matTooltip]="'v2.template.style.reset' | translate">
                                Reset
                            </button>
                        </div>
                    </ng-container>
                </ng-template>
            </codex-viewport-selector>
        </div>
    </ng-container>
    <h4 class="!text-sm !font-bold !mb-1 !mt-1">{{ 'Minimum size' }}</h4>
    <ng-container *ngIf="showGridInput && observables.partPositioningData?.currentPartPositioning">
        <div class="flex mb-2 gap-2">
            <codex-viewport-selector
                class="w-[calc((100%/2)-5px)] flex gap-1"
                [indicateResponsiveSettings]="responseSettingsForMinSizeX(observables.partPositioningData)"
                [viewPort]="observables.partPositioningData.currentPartPositioning.minSizeX"
                [unit]="observables.partPositioningData.currentPartPositioning.minSizeXUnit"
                [allowedUnits]="allowedUnits"
                label="W"
                (viewPortChange)="onChangeMinSizeX($event, observables.partPositioningData.currentPartPositioning)">
                <ng-template #partPositioningSettings>
                    <ng-container
                        *ngFor="let partPositioning of observables.partPositioningData.partPositions; trackBy: identifyPartPositioning">
                        <div
                            *ngIf="partPositioning.screenType !== observables.partPositioningData.currentPartPositioning.screenType"
                            class="flex gap-2 items-center mb-2">
                            <svg *ngIf="partPositioning.screenType === 'SMALL'" class="icon" aria-hidden="true" focusable="false">
                                <use href="#mobile-screens" class="stroke-white"></use>
                            </svg>
                            <svg *ngIf="partPositioning.screenType === 'LARGE'" class="icon" aria-hidden="true" focusable="false">
                                <use href="#tablet-screens" class="stroke-white"></use>
                            </svg>
                            <svg
                                *ngIf="partPositioning.screenType === 'EXTRA_EXTRA_LARGE'"
                                class="icon"
                                aria-hidden="true"
                                focusable="false">
                                <use href="#large-screens" class="stroke-white"></use>
                            </svg>
                            <svg *ngIf="partPositioning.screenType === 'ALL_SCREENS'" class="icon" aria-hidden="true" focusable="false">
                                <use href="#all-screens" class="fill-white"></use>
                            </svg>
                            <codex-viewport-selector
                                class="flex gap-1"
                                [viewPort]="partPositioning.minSizeX"
                                [unit]="partPositioning.minSizeXUnit"
                                [allowedUnits]="allowedUnits"
                                label="W"
                                (viewPortChange)="onChangeMinSizeX($event, partPositioning)">
                            </codex-viewport-selector>
                            <button
                                class="text-button"
                                type="button"
                                (click)="$event.stopPropagation()"
                                [matTooltip]="'v2.template.style.reset' | translate">
                                Reset
                            </button>
                        </div>
                    </ng-container>
                </ng-template>
            </codex-viewport-selector>
            <codex-viewport-selector
                class="w-[calc((100%/2)-5px)] flex gap-1"
                [indicateResponsiveSettings]="responseSettingsForMinSizeY(observables.partPositioningData)"
                [viewPort]="observables.partPositioningData.currentPartPositioning.minSizeY"
                [unit]="observables.partPositioningData.currentPartPositioning.minSizeYUnit"
                [allowedUnits]="allowedUnits"
                label="H"
                (viewPortChange)="onChangeMinSizeY($event, observables.partPositioningData.currentPartPositioning)">
                <ng-template #partPositioningSettings>
                    <ng-container
                        *ngFor="let partPositioning of observables.partPositioningData.partPositions; trackBy: identifyPartPositioning">
                        <div
                            *ngIf="partPositioning.screenType !== observables.partPositioningData.currentPartPositioning.screenType"
                            class="flex gap-2 items-center mb-2">
                            <svg *ngIf="partPositioning.screenType === 'SMALL'" class="icon" aria-hidden="true" focusable="false">
                                <use href="#mobile-screens" class="stroke-white"></use>
                            </svg>
                            <svg *ngIf="partPositioning.screenType === 'LARGE'" class="icon" aria-hidden="true" focusable="false">
                                <use href="#tablet-screens" class="stroke-white"></use>
                            </svg>
                            <svg
                                *ngIf="partPositioning.screenType === 'EXTRA_EXTRA_LARGE'"
                                class="icon"
                                aria-hidden="true"
                                focusable="false">
                                <use href="#large-screens" class="stroke-white"></use>
                            </svg>
                            <svg *ngIf="partPositioning.screenType === 'ALL_SCREENS'" class="icon" aria-hidden="true" focusable="false">
                                <use href="#all-screens" class="fill-white"></use>
                            </svg>
                            <codex-viewport-selector
                                class="flex gap-1"
                                [viewPort]="partPositioning.minSizeY"
                                [unit]="partPositioning.minSizeYUnit"
                                [allowedUnits]="allowedUnits"
                                label="H"
                                (viewPortChange)="onChangeMinSizeY($event, partPositioning)">
                            </codex-viewport-selector>
                            <button
                                class="text-button"
                                type="button"
                                (click)="$event.stopPropagation()"
                                [matTooltip]="'v2.template.style.reset' | translate">
                                Reset
                            </button>
                        </div>
                    </ng-container>
                </ng-template>
            </codex-viewport-selector>
        </div>
    </ng-container>
    <h4 class="!text-sm !font-bold !mb-1 !mt-1">{{ 'Maximum size' }}</h4>
    <ng-container *ngIf="showGridInput && observables.partPositioningData?.currentPartPositioning">
        <div class="flex mb-2 gap-2">
            <codex-viewport-selector
                class="w-[calc((100%/2)-5px)] flex gap-1"
                [indicateResponsiveSettings]="responseSettingsForMaxSizeX(observables.partPositioningData)"
                [viewPort]="observables.partPositioningData.currentPartPositioning.maxSizeX"
                [unit]="observables.partPositioningData.currentPartPositioning.maxSizeXUnit"
                [allowedUnits]="allowedUnits"
                label="W"
                (viewPortChange)="onChangeMaxSizeX($event, observables.partPositioningData.currentPartPositioning)">
                <ng-template #partPositioningSettings>
                    <ng-container
                        *ngFor="let partPositioning of observables.partPositioningData.partPositions; trackBy: identifyPartPositioning">
                        <div
                            *ngIf="partPositioning.screenType !== observables.partPositioningData.currentPartPositioning.screenType"
                            class="flex gap-2 items-center mb-2">
                            <svg *ngIf="partPositioning.screenType === 'SMALL'" class="icon" aria-hidden="true" focusable="false">
                                <use href="#mobile-screens" class="stroke-white"></use>
                            </svg>
                            <svg *ngIf="partPositioning.screenType === 'LARGE'" class="icon" aria-hidden="true" focusable="false">
                                <use href="#tablet-screens" class="stroke-white"></use>
                            </svg>
                            <svg
                                *ngIf="partPositioning.screenType === 'EXTRA_EXTRA_LARGE'"
                                class="icon"
                                aria-hidden="true"
                                focusable="false">
                                <use href="#large-screens" class="stroke-white"></use>
                            </svg>
                            <svg *ngIf="partPositioning.screenType === 'ALL_SCREENS'" class="icon" aria-hidden="true" focusable="false">
                                <use href="#all-screens" class="fill-white"></use>
                            </svg>
                            <codex-viewport-selector
                                class="flex gap-1"
                                [viewPort]="partPositioning.maxSizeX"
                                [unit]="partPositioning.maxSizeXUnit"
                                [allowedUnits]="allowedUnits"
                                label="W"
                                (viewPortChange)="onChangeMaxSizeX($event, partPositioning)">
                            </codex-viewport-selector>
                            <button
                                class="text-button"
                                type="button"
                                (click)="$event.stopPropagation()"
                                [matTooltip]="'v2.template.style.reset' | translate">
                                Reset
                            </button>
                        </div>
                    </ng-container>
                </ng-template>
            </codex-viewport-selector>
            <codex-viewport-selector
                class="w-[calc((100%/2)-5px)] flex gap-1"
                [indicateResponsiveSettings]="responseSettingsForMaxSizeY(observables.partPositioningData)"
                [viewPort]="observables.partPositioningData.currentPartPositioning.maxSizeY"
                [unit]="observables.partPositioningData.currentPartPositioning.maxSizeYUnit"
                [allowedUnits]="allowedUnits"
                label="H"
                (viewPortChange)="onChangeMaxSizeY($event, observables.partPositioningData.currentPartPositioning)">
                <ng-template #partPositioningSettings>
                    <ng-container
                        *ngFor="let partPositioning of observables.partPositioningData.partPositions; trackBy: identifyPartPositioning">
                        <div
                            *ngIf="partPositioning.screenType !== observables.partPositioningData.currentPartPositioning.screenType"
                            class="flex gap-2 items-center mb-2">
                            <svg *ngIf="partPositioning.screenType === 'SMALL'" class="icon" aria-hidden="true" focusable="false">
                                <use href="#mobile-screens" class="stroke-white"></use>
                            </svg>
                            <svg *ngIf="partPositioning.screenType === 'LARGE'" class="icon" aria-hidden="true" focusable="false">
                                <use href="#tablet-screens" class="stroke-white"></use>
                            </svg>
                            <svg
                                *ngIf="partPositioning.screenType === 'EXTRA_EXTRA_LARGE'"
                                class="icon"
                                aria-hidden="true"
                                focusable="false">
                                <use href="#large-screens" class="stroke-white"></use>
                            </svg>
                            <svg *ngIf="partPositioning.screenType === 'ALL_SCREENS'" class="icon" aria-hidden="true" focusable="false">
                                <use href="#all-screens" class="fill-white"></use>
                            </svg>
                            <codex-viewport-selector
                                class="flex gap-1"
                                [viewPort]="partPositioning.maxSizeY"
                                [unit]="partPositioning.maxSizeYUnit"
                                [allowedUnits]="allowedUnits"
                                label="H"
                                (viewPortChange)="onChangeMaxSizeY($event, partPositioning)">
                            </codex-viewport-selector>
                            <button
                                class="text-button"
                                type="button"
                                (click)="$event.stopPropagation()"
                                [matTooltip]="'v2.template.style.reset' | translate">
                                Reset
                            </button>
                        </div>
                    </ng-container>
                </ng-template>
            </codex-viewport-selector>
        </div>
    </ng-container>
</ng-container>
