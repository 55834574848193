<mat-dialog-content class="!p-4">
    <h1 class="!mb-2">{{ 'company.edit.permissions.title' | translate }}</h1>
    <form [formGroup]="form">
        <select id="select-application" class="small-input" [formControl]="applicationControl">
            <option selected value="">Select application</option>
            <option *ngFor="let application of applications$ | async" [value]="application.id">
                {{ application.name }}
            </option>
        </select>
        <div *ngIf="groupsAndRoles$ | async as data; else selectApp">
            <div class="roles" *ngIf="data.applicationBackofficeRoles as roles">
                <h2>{{ 'company.edit.permissions.application.backoffice.roles.title' | translate }}</h2>
                <mat-grid-list cols="4" rowHeight="40" class="roles__content">
                    <ng-container *ngIf="roles.length != 0; else noRoles">
                        <mat-grid-tile class="!overflow-visible text-text_2">
                            <input type="checkbox" class="checkbox" (click)="toggleAll($event, roles)" />
                            <label class="!mb-0 small-input-label">Toggle all</label>
                        </mat-grid-tile>
                        <mat-grid-tile *ngFor="let role of roles" style="overflow: visible" class="!overflow-visible text-text_2">
                            <input
                                type="checkbox"
                                class="checkbox"
                                [attr.id]="role.id"
                                [value]="role.id"
                                [checked]="role.checked"
                                (click)="onRoleSelected(role, role.companyId, role.applicationId)" />
                            <label [attr.for]="role.id" class="!mb-0 small-input-label">{{
                                'company.edit.permissions.application.backoffice.roles.role.' + role.name | translate
                            }}</label>
                        </mat-grid-tile>
                    </ng-container>
                    <ng-template #noRoles>
                        <p>{{ 'company.edit.permissions.roles.no.found' | translate }}</p>
                    </ng-template>
                </mat-grid-list>
            </div>
            <div class="roles" *ngIf="data.companyBackofficeRoles as roles">
                <h2>{{ 'company.edit.permissions.company.backoffice.roles.title' | translate }}</h2>
                <mat-grid-list cols="4" rowHeight="40" class="roles__content">
                    <ng-container *ngIf="roles.length != 0; else noRoles">
                        <mat-grid-tile class="!overflow-visible text-text_2">
                            <input type="checkbox" class="checkbox" (click)="toggleAll($event, roles)" />
                            <label class="!mb-0 small-input-label">Toggle all</label>
                        </mat-grid-tile>
                        <mat-grid-tile *ngFor="let role of roles" style="overflow: visible" class="!overflow-visible text-text_2">
                            <input
                                type="checkbox"
                                class="checkbox"
                                [attr.id]="role.id"
                                [value]="role.id"
                                [checked]="role.checked"
                                (click)="onRoleSelected(role, role.companyId, role.applicationId)" />
                            <label [attr.for]="role.id" class="!mb-0 small-input-label">{{
                                'company.edit.permissions.company.backoffice.roles.role.' + role.name | translate
                            }}</label>
                        </mat-grid-tile>
                    </ng-container>
                    <ng-template #noRoles>
                        <p>{{ 'company.edit.permissions.roles.no.found' | translate }}</p>
                    </ng-template>
                </mat-grid-list>
            </div>
        </div>
        <ng-template #selectApp>
            <p class="text-xs text-text_2">{{ 'company.edit.permissions.application.select' | translate }}</p>
        </ng-template>
        <div class="flex justify-end gap-1">
            <button class="primary-button button-large" [mat-dialog-close]="true">
                <mat-icon>done</mat-icon>{{ 'general.ok' | translate }}
            </button>
        </div>
    </form>
</mat-dialog-content>
