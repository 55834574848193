import {
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
} from '@angular/core';
import { FormControl, FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { GUIDFunctions } from '@shared/utils';
import { DesignSystem, PartPositioning } from '@backoffice/data-access/editor';
import { NgxFloatUiPlacements, NgxFloatUiTriggers } from 'ngx-float-ui';
import { debounceTime } from 'rxjs/operators';
import { backofficeEnvironment } from '@shared/environment';

export interface ViewPortUnits {
    value: string;
    display: string;
    description?: string;
}

@Component({
    selector: 'codex-viewport-selector',
    templateUrl: './viewport-selector.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewportSelectorComponent implements OnInit, OnDestroy {
    private readonly DEFAULT_VALUE = 0;
    private readonly DEFAULT_UNIT = 'pixels';
    private readonly DEFAULT_UNITS: Map<string, ViewPortUnits> = new Map([
        ['pixels', { value: 'pixels', display: 'px' }],
        ['rem', { value: 'rem', display: 'rem' }],
        ['em', { value: 'em', display: 'em' }],
        ['percentage', { value: 'percentage', display: '%' }],
        ['viewport', { value: 'viewport', display: 'vp' }],
        ['grid', { value: 'grid', display: 'grid' }],
        ['grid-end', { value: 'grid-end', display: 'grid end' }],
        ['grid-start', { value: 'grid-start', display: 'grid start' }],
        ['unset', { value: 'unset', display: 'unset' }],
        ['fit-content', { value: 'fit-content', display: 'fit-content' }],
    ]);

    _allowedUnits: Map<string, ViewPortUnits> = this.DEFAULT_UNITS;
    dropdownVisible = false;
    subscription = new Subscription();

    @Input() label: string;

    @Input() viewPort: number;

    @Input() unit: string;

    @ContentChild('partPositioningSettings') partPositioningSettings: TemplateRef<any>;

    @Input() formGoup: FormGroup<{
        value: FormControl<number>;
        unit: FormControl<string>;
    }>;

    @Input() indicateResponsiveSettings: boolean = false;

    identifier: string;

    @Input() set allowedUnits(values: string[]) {
        this._allowedUnits = new Map();
        values.forEach(v => {
            const viewPortUnit = this.DEFAULT_UNITS.get(v);
            if (viewPortUnit != null) {
                this._allowedUnits.set(v, viewPortUnit);
            }
        });
    }

    @Output() viewPortChange: EventEmitter<{ viewPort: number; unit: string }> = new EventEmitter();

    @HostListener('document:click', ['$event'])
    clickout(event: any) {
        if (!this.eRef.nativeElement.contains(event.target)) {
            this.dropdownVisible = false;
        }
    }

    constructor(
        private readonly fb: NonNullableFormBuilder,
        private readonly eRef: ElementRef
    ) {}

    ngOnInit() {
        if (!this.formGoup) {
            this.formGoup = this.fb.group({
                value: [this.DEFAULT_VALUE, Validators.required],
                unit: [this.DEFAULT_UNIT, Validators.required],
            });
            this.subscription.add(
                this.formGoup.valueChanges
                    .pipe(debounceTime(backofficeEnvironment.inputdebounce))
                    .subscribe(() => this.handleValueChanged())
            );

            if (!!this.viewPort) {
                this.formGoup.controls.value.setValue(this.viewPort, { emitEvent: false });
            }

            if (!!this.unit) {
                this.formGoup.controls.unit.setValue(this.unit, { emitEvent: false });
            }
            this.identifier = new GUIDFunctions().newGuid();
        } else {
            this.subscription.add(
                this.formGoup.valueChanges
                    .pipe(debounceTime(backofficeEnvironment.inputdebounce))
                    .subscribe(() => this.handleValueChanged())
            );
        }
    }

    ngOnChanges() {
        if (this.formGoup) {
            if (!!this.viewPort) {
                this.formGoup.controls.value.setValue(this.viewPort, { emitEvent: false });
            }
            if (!!this.unit) {
                this.formGoup.controls.unit.setValue(this.unit, { emitEvent: false });
            }
        }
    }

    ngOnDestroy() {
        if (this.subscription && !this.subscription.closed) {
            this.subscription.unsubscribe();
        }
    }

    handleValueChanged(): void {
        this.emitChange();
    }

    private emitChange(): void {
        const { unit, value } = this.formGoup.getRawValue();
        this.viewPortChange.emit({ viewPort: value, unit });
    }

    protected readonly NgxFloatUiTriggers = NgxFloatUiTriggers;
    protected readonly NgxFloatUiPlacements = NgxFloatUiPlacements;
}
